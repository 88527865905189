@import "orders";

// Here you can add other styles
.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus,
.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
  border: 1px solid #cfcfcf !important;
  // border-color: rgba($color: #000000, $alpha: 0) !important;
  box-shadow: unset !important;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  z-index: 0 !important;
  border-color: #e55353;
  background-image: unset !important;
}

.was-validated .form-control:valid,
.form-control.is-valid {
  border: 1px solid #cfcfcf !important;
  // border-color: rgba($color: #000000, $alpha: 0) !important;
  background-image: unset !important;
}

.form-select:focus {
  border-color: unset !important;
  box-shadow: unset !important;
}

.modal-footer {
  border-top: unset !important;
}

.modal-header {
  border-bottom: unset !important;
}

:root {
  --cui-body-font-size: 0.875rem !important;
}
.MuiToolbar-root {
  z-index: 0 !important;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: rgb(255 255 255 / 87%) !important;
  background-color: var(--cui-nav-pills-link-active-bg) !important;
}
.nav-pills .nav-link {
  background: white !important;
  border: 0 !important;
  border-radius: var(--cui-nav-pills-border-radius) !important;
}

.remove-colorBg-accordion {
  --cui-accordion-active-color: unset !important;
  --cui-accordion-active-bg: unset !important;
  --cui-accordion-btn-focus-box-shadow: unset !important;
  --cui-accordion-btn-focus-border-color: unset !important;
  --cui-accordion-btn-color: unset !important;
  --cui-accordion-border-color: unset !important;
  --cui-accordion-body-padding-x: 0.25rem;
  --cui-accordion-btn-padding-x: 0.25rem;
  // --cui-accordion-border-width: ;
  // --cui-accordion-border-radius: 0.375rem;
}

.custom-accordion-borderColor {
  // --cui-accordion-btn-focus-border-color: unset !important;
  --cui-accordion-btn-focus-box-shadow: unset !important;
  // --cui-accordion-border-color: unset !important;
  // --cui-accordion-btn-focus-border-color: unset !important;
}

.pointer {
  * {
    cursor: pointer;
    user-select: none;
  }
}

.product-title {
  color: #4163d0;
  font-weight: 700;
  font-size: 0.875rem;
}
