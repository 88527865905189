// If you want to override variables do it here
@import "variables";

$enable-ltr: true;
$enable-rtl: true;

// Import CoreUI for React components library
@import "@coreui/coreui/scss/coreui";

// Import Chart.js custom tooltips styles
@import "@coreui/chartjs/scss/coreui-chartjs";

@import "layout";
@import "example";

// If you want to add custom CSS you can put it here.
@import "custom";

.input-group:not(.has-validation)
  > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
  border-bottom-right-radius: 6px !important;
  border-top-right-radius: 6px !important;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--cui-input-color, rgba(44, 56, 74, 0.95));
  background-color: var(--cui-input-bg, #fff);
  background-clip: padding-box;
  border: 1px solid #cfcfcf;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control:focus {
  color: var(--cui-input-focus-color, rgba(44, 56, 74, 0.95));
  background-color: "!important";
  border-color: #cfcfcf;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(255 255 255 / 25%);
}

li.nav-group
  > ul.nav-group-items
  > li.nav-group
  > ul.nav-group-items
  > li.nav-item
  a.nav-link {
  padding-left: 5em !important;
  white-space: pre-line !important;
}

li.nav-group > ul.nav-group-items > li.nav-item a.nav-link {
  white-space: pre-line !important;
}

.bg-abu {
  color: grey;
}

.btn-check:focus + .btn,
.btn:focus {
  box-shadow: unset !important;
}
.postion-center {
  text-align: center;
}

.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: unset !important;
}

.imagePreviewWrapper {
  width: 28.125rem;
  height: 15.625rem;
  display: block;
  cursor: pointer;
  margin: 0 auto 30px;
  background-size: cover;
  background-position: center center;
}
// config checkout
.checkout {
  color: red !important;
}
.checkout-container {
  display: flex;
  align-items: center;
}
.border-co {
  border: 1px solid #8f8f8f;
  border-radius: 8px;
  padding: 0.5rem;
  width: 100%;
}
//notification
.notification {
  border: 2px solid #8f8f8f;
  height: 170px;
  border-radius: 8px;
  padding: 1rem;
}
.notification-img {
  display: flex;
  align-items: center;
}
.notification-img img{
  width: 100%;
  height: 8rem;
}
.imgnotif {
  border: 1px solid #8f8f8f;
  border-radius: 8px;
}
.notification-img2 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.news-desc{
  overflow:hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.text-decoration {
  text-decoration: none;
  color: black;
}
.text-decoration:hover {
  color: blue;
}
.add-more{
  background-color: white;
}
.btn-blue{
  background: #0054E6 !important;
}
.btn-blue[disabled] {
  background-color: #A3A3A3 !important;
  border-color: #A3A3A3 !important;
}
.card-login{
  width: 50%;
}
@media screen and (max-width: 1280px) {
  .card-login {
    width: 73%;
  }
}